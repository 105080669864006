import { useTexture } from "@react-three/drei";
import React from "react";
import { MeshBasicMaterial, PlaneGeometry, sRGBEncoding } from "three";
import { extend, useThree } from "@react-three/fiber";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
// import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import * as THREE from "three";
import almendra from "../../almendra.json";

// extend({ TextGeometry }); // extend the geometry with the name of the class
// this seems like a generic interface (maybe move it to a generic folder?)
// todo: extract this
interface PaintingProps {
  name: string;
  paintingPosition?: [number, number, number];
  framePosition?: [number, number, number];
  spotLightPosition?: [number, number, number];
}

const Text = ({
  name = "",
  paintingPosition,
  framePosition,
  spotLightPosition,
}: PaintingProps): JSX.Element => {
  const texture = useTexture(`paintings/${name}.png`);
  texture.encoding = sRGBEncoding;
  const geometry = new PlaneGeometry(7.5, 3.3);
  geometry.rotateY(-1.5);
  const material = new MeshBasicMaterial({
    map: texture,
  });

  const font = new FontLoader().parse(almendra);

  // the next values are hardcoded just for the demo
  // ideally we should have them as props + useHelper hook for the box
  // todo: refactor
  return (
    <>
      <mesh>
        {/* <TextGeometry
          attach="geometry"
          args={["Hello World!", { font, size: 1, height: 1 }]}
        /> */}
      </mesh>
    </>
  );
};
export default Text;
