import { useTexture } from "@react-three/drei";
import React from "react";
import { MeshBasicMaterial, PlaneGeometry, sRGBEncoding } from "three";

// this seems like a generic interface (maybe move it to a generic folder?)
// todo: extract this
interface PaintingProps {
  name: string;
  paintingPosition?: [number, number, number];
  framePosition?: [number, number, number];
  spotLightPosition?: [number, number, number];
  rotation?: [number, number, number];
  photoDimensionWidth?: number;
  photoDimensionHeight?: number;
  frameDimensions?: [number, number, number];
}

const Painting = ({
  name = "",
  paintingPosition,
  framePosition,
  spotLightPosition,
  rotation,
  photoDimensionWidth,
  photoDimensionHeight,
  frameDimensions,
}: PaintingProps): JSX.Element => {
  const texture = useTexture(`paintings/${name}.png`);
  texture.encoding = sRGBEncoding;
  const geometry = new PlaneGeometry(
    photoDimensionWidth || 2.5,
    photoDimensionHeight || 3.3
  );
  const material = new MeshBasicMaterial({
    map: texture,
  });

  // the next values are hardcoded just for the demo
  // ideally we should have them as props + useHelper hook for the box
  // todo: refactor
  return (
    <>
      <spotLight
        intensity={3.5}
        position={spotLightPosition}
        color={"Yellow"}
        distance={10}
        decay={1.5}
        power={20}
        rotation={rotation || [0, 0, 0]}
      />
      <mesh
        position={paintingPosition}
        args={[geometry, material]}
        rotation={rotation || [0, 0, 0]}
      />
      <mesh position={framePosition} rotation={rotation || [0, 0, 0]}>
        <boxGeometry args={frameDimensions || [2.7, 3.5, 0.1]} />
        <meshStandardMaterial color="Black" />
      </mesh>
    </>
  );
};
export default Painting;
