import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import Controls from "components/three/controls";
import Gallery from "components/three/gallery";
import Painting from "components/three/painting";
import Text from "components/three/text";
import { Loader as CanvasLoader, Preload } from "@react-three/drei";
import { Mesh } from "three";

const IndexPage = (): JSX.Element => {
  const [floor, setFloor] = useState<Mesh>();

  return (
    <>
      <Canvas>
        <ambientLight intensity={0.8} />
        <Suspense fallback={null}>
          <Controls floor={floor} />
          <Painting
            name="poster1"
            paintingPosition={[8, 1.6, -5.7]}
            framePosition={[8.01, 1.61, -5.76]}
            spotLightPosition={[0, 7, 0.7]}
          />

          <Painting
            name="chips"
            paintingPosition={[9.34, 1.6, -3.64]}
            framePosition={[9.41, 1.61, -3.66]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI / 2, 0]}
          />

          <Painting
            name="popArt"
            paintingPosition={[9.34, 1.6, -0.04]}
            framePosition={[9.41, 1.61, -0.06]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI / 2, 0]}
          />

          <Painting
            name="soup"
            paintingPosition={[9.34, 1.6, 3.54]}
            framePosition={[9.41, 1.61, 3.56]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI / 2, 0]}
          />

          <Painting
            name="rectangle"
            paintingPosition={[5.31, 1.6, 5.7]}
            framePosition={[5.31, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
            photoDimensionWidth={6.3}
            frameDimensions={[6.6, 3.6, 0.1]}
          />

          <Painting
            name="uncle"
            paintingPosition={[-5.11, 1.6, 5.7]}
            framePosition={[-5.11, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
          />

          <Painting
            name="aunty"
            paintingPosition={[-8.15, 1.6, 5.7]}
            framePosition={[-8.15, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
          />

          <Painting
            name="soup2"
            paintingPosition={[-11.21, 1.6, 5.7]}
            framePosition={[-11.21, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
          />

          <Painting
            name="uncleaunty"
            paintingPosition={[-20.11, 1.6, 5.7]}
            framePosition={[-20.11, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
          />

          <Painting
            name="onlyaunty"
            paintingPosition={[-23.51, 1.6, 5.7]}
            framePosition={[-23.51, 1.61, 5.76]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, -Math.PI, 0]}
          />

          <Painting
            name="uncle2"
            paintingPosition={[-25.74, 1.6, 3.96]}
            framePosition={[-25.81, 1.61, 3.96]}
            spotLightPosition={[0, 7, 0.7]}
            rotation={[0, Math.PI / 2, 0]}
          />

          <Painting
            name="poster2"
            paintingPosition={[4, 1.6, -5.7]}
            framePosition={[4.01, 1.61, -5.76]}
            spotLightPosition={[0, 7, 0.7]}
          />

          <Painting
            name="poster3"
            paintingPosition={[0, 1.6, -5.7]}
            framePosition={[0.01, 1.61, -5.76]}
            spotLightPosition={[0, 7, 0.7]}
          />

          <Painting
            name="poster4"
            paintingPosition={[-4, 1.6, -5.7]}
            framePosition={[-4.02, 1.61, -5.76]}
            spotLightPosition={[-10, 7, 0.7]}
          />

          <Painting
            name="poster5"
            paintingPosition={[-8, 1.6, -5.7]}
            framePosition={[-8.02, 1.61, -5.76]}
            spotLightPosition={[-10, 7, 0.7]}
          />

          <Painting
            name="poster6"
            paintingPosition={[-12, 1.6, -5.7]}
            framePosition={[-12.02, 1.61, -5.76]}
            spotLightPosition={[-10, 7, 0.7]}
          />

          <Painting
            name="poster7"
            paintingPosition={[-16, 1.6, -5.7]}
            framePosition={[-16.02, 1.61, -5.76]}
            spotLightPosition={[-20, 7, 0.7]}
          />

          <Painting
            name="poster8"
            paintingPosition={[-20, 1.6, -5.7]}
            framePosition={[-20.02, 1.61, -5.76]}
            spotLightPosition={[-20, 7, 0.7]}
          />

          <Painting
            name="poster9"
            paintingPosition={[-24, 1.6, -5.7]}
            framePosition={[-24.02, 1.61, -5.76]}
            spotLightPosition={[-20, 7, 0.7]}
          />

          <Text
            name="title"
            paintingPosition={[9.2, 1.6, 0.7]}
            framePosition={[-4.02, 1.61, -5.76]}
            spotLightPosition={[-20, 7, 0.7]}
          />

          <Gallery setFloor={setFloor} />
          <Preload all />
        </Suspense>
      </Canvas>
      <CanvasLoader
        barStyles={{ height: "10px" }}
        dataStyles={{ fontSize: "14px" }}
        dataInterpolation={(percent) => `Loading ${percent.toFixed(0)}%`}
      />
    </>
  );
};

export default IndexPage;
